import React, { useEffect } from 'react'
import { Link, graphql } from 'gatsby'
import SEO from '../components/seo'
import GlobalUSP from '../components/global-usp'
import Figure from '../components/figure'
import ReviewStars from '../components/review-stars'
import Reviews from '../components/reviews'
import ArrowRightIcon from '../images/arrow-right.svg'
import BlockContent from '../components/block-content'
import BlogPostPreview from '../components/blog-post-preview'
import Marquee from '../components/marquee'
import { mapEdgesToNodes } from '../utils/helpers'
import HyphenatedTitle from '../components/hyphenatedTitle'
import InternalLink from '../components/internalLink'
import InternalOrExternalLink from '../components/internalOrExternalLink'
import KlaviyoSignupForm from '../components/klaviyo-signup-form'

const IndexPage = props => {
  const { data, errors } = props
  const page = data && data.page
  const pageDescriptionBlock = data && data.site && data.site.pageDescriptionBlock
  const blog = data && data.blog && mapEdgesToNodes(data.blog)
  const categories = data && data.categories && mapEdgesToNodes(data.categories)

  const LinkWrapper = ({ item, children, ...props }) => {
    return (
      <>
        {item.internalLink && (
          <InternalLink reference={item.internalLink.reference} {...props}>{children}</InternalLink>
        )}

        {!item.internalLink && (
          <InternalOrExternalLink to={item.url} {...props}>{children}</InternalOrExternalLink>
        )}
      </>
    )
  }

  return (
    <>
      <SEO page={page} />

      <div className='bg-peach-lighter header-offset'>
        <div className='pt-10 lg:pt-20 pb-20 md:pb-40 flex flex-col xxl:flex-row xxxl:items-center'>
          <div className="mx-5 lg:mx-10 xl:mx-20 md:mr-8 mb-6 xxl:w-1/3">
            <div>
              <h1 className='text-purple font-bold md:text-5xl xxl:text-6xl'>
                Din kropp. Dine valg.
              </h1>
              <div className="mt-4">
                <ul className="lead list-disc pl-6">
                  <li>Digital konsultasjon når <strong>du</strong> trenger det</li>
                  <li>Betal kun om vi kan hjelpe deg</li>
                  <li>Rask svartid</li>
                </ul>
                <ReviewStars
                  className="mt-6"
                  title='Dette sier andre om oss'
                  showTotals
                />
              </div>
            </div>
          </div>

          <div className='mx-5 lg:mx-10 xl:mx-20 xxl:mx-0 xxl:mr-20 xxl:w-2/3'>
            <div className='-mx-2 xxl:mx-0 flex flex-wrap xxxl:flex-no-wrap mb-10'>
              {page.featuredServices.map((service, i) => (
                <LinkWrapper
                  item={service}
                  key={i}
                  className='inline-block relative px-2 w-1/2 md:w-1/3 xxxl:w-1/6 mb-4 no-underline'
                  title={service.text}
                >
                  <Figure aspectRatio={.85} node={service.image} className='rounded-tl-md rounded-tr-md overflow-hidden' />

                  <div className="bg-white rounded-bl-md rounded-br-md p-3">
                    <div className='w-full flex items-center'>
                      <span className="inline-block overflow-hidden truncate">
                        {service.text}
                      </span>
                    </div>
                    {service.internalLink?.reference.defaultPrice && (
                    <div className="flex justify-between items-center font-bold text-primary text-sm">
                      <span>fra {service.internalLink?.reference.defaultPrice} kr</span>
                    </div>
                    )}
                    {service.internalLink?.reference.fromPrice && (
                    <div className="flex justify-between items-center font-bold text-primary text-sm">
                      <span>fra {service.internalLink?.reference.fromPrice} kr</span>
                    </div>
                    )}
                  </div>
                </LinkWrapper>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className='md:flex bg-yellow-lighter'>
        {!!page.image && (
          <Figure node={page.image} className='md:w-1/2 flex-shrink-0 md:-mt-20' />
        )}

        {!!page._rawSlogan && (
          <BlockContent className='text-purple font-serif h3 mb-0 md:text-screen-1 flex content-center items-center p-8 py-16 lg:p-20 xl:px-32' blocks={page._rawSlogan} />
        )}
      </div>

      <Marquee></Marquee>

      {page.howItWorksText && (
        <section className='py-20 lg:py-32 px-8 lg:px-0 bg-red-lighter'>
          <div className='lg:text-center mb-12'>
            <h2 className='font-bold text-peach font-serif'>Slik fungerer det</h2>
          </div>

          <div className='lg:flex max-w-lg lg:max-w-screen-xl lg:mx-auto'>
            {page.howItWorksText.map((row, i) => (
              <div key={i} className='mb-12 last:mb-0 lg:mb-0 lg:flex-1 lg:px-12 lg:text-center'>
                {row.image && <Figure node={row.image} className='w-24 mb-4 lg:mb-8 lg:mx-auto full-width-image' />}
                <h3 className='h4 font-serif text-peach'>{row.title}</h3>
                <BlockContent blocks={row.text} className='small' />
              </div>
            ))}
          </div>
        </section>
      )}

      <Reviews
        className='bg-purple-lighter py-20 lg:py-32'
        preTitle='Hundrevis har gitt oss 5/5 ⭐️'
        title='Du hørte det ikke fra oss.'
      />

      <div className='bg-white p-8 lg:pt-8 xl:py-16'>
          <GlobalUSP className='block lg:flex justify-center content-center max-w-screen-xl mx-auto' />
      </div>

      {page.experts && page.experts.length > 0 && (
        <section id='eksperter' className='px-8 py-20 lg:py-32 bg-primary-background'>
          <div className='max-w-screen-lg lg:flex mx-auto items-center'>
            <div className='flex-1 lg:pr-12 mb-12 lg:mb-0'>
              <h2 className='font-bold text-primary font-serif'>Våre leger</h2>
              {page._rawExpertsText && <BlockContent className='' blocks={page._rawExpertsText} />}
            </div>

            <div className='flex-1 flex'>
              {page.experts.map((expert, i) => (
                <div
                  className='mx-auto flex flex-col items-center text-center'
                  key={i}
                >
                  <div className={`w-32 md:w-56 mb-4`}>
                    {expert.image && (
                      <Figure className='overflow-hidden rounded-full' node={expert.image} aspectRatio={1} />
                    )}
                  </div>
                  <div className={`flex flex-col justify-center w-full`}>
                    {expert.name && <div className='text-primary font-bold mb-4'>{expert.name}</div>}
                    {expert.position && <div className='text-xs'>{expert.position}</div>}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      )}

      <div className='bg-red-lighter md:flex items-center'>
        {!!pageDescriptionBlock && !!pageDescriptionBlock.image && (
          <Figure node={pageDescriptionBlock.image} className='md:w-1/2 flex-shrink-0' aspectRatio={1} />
        )}

        <div className='p-5 pb-8 md:p-16'>
          <h2 className='font-serif text-yellow mb-8'>Vi kan hjelpe deg med</h2>
          {page._rawOurServicesText && <BlockContent className='mb-8' blocks={page._rawOurServicesText} />}

          {page.featuredServices.map((service, i) => (
            <LinkWrapper
              item={service}
              key={i}
              className='flex items-center relative px-2 w-1/2 mb-4 lead'
            >
              <img src={ArrowRightIcon} alt='' className='w-4 mr-6' />
              {service.text}
            </LinkWrapper>
          ))}
        </div>
      </div>

      {blog && blog.length > 0 && (
        <div className='py-20 lg:py-32 px-4 max-w-screen-lg mx-auto'>
          <h2 className='font-bold text-primary font-serif text-center mb-8 lg:mb-12'>Artikler fra bloggen</h2>

          <div className='flex flex-wrap md:-mx-4'>
            {blog.map(node => (
              <BlogPostPreview className='my-8 w-full md:px-4 md:w-2/4' key={node.id} compact {...node} />
            ))}
          </div>
        </div>
      )}

      {page.followUsText && (
        <div className='px-8 py-20 lg:py-32 bg-peach-lighter text-center'>
          <div className='max-w-lg mx-auto'>
            <h2 className='text-red font-serif font-bold mb-4'>{page.followUsText.title}</h2>
            <BlockContent className='lead' blocks={page.followUsText._rawText} />
          </div>
        </div>
      )}

      <div className='px-8 py-20 bg-red-lighter'>
        <div className='max-w-screen-lg mx-auto md:flex items-center'>
          <div className='flex-shrink-0 md:w-1/3'>
            <h2 className='h3 font-serif font-bold text-purple mb-8 md:mb-0'>Meld deg på vårt nyhetsbrev</h2>
          </div>

          <KlaviyoSignupForm className='klaviyo' listId='SQRwTX' />
        </div>
      </div>

      {page.relatedSiteInfoBox && page.relatedSiteInfoBox.title && (
        <div className='md:flex items-center' style={{backgroundColor: '#DBE7DA'}}>
          {!!page.relatedSiteInfoBox && !!page.relatedSiteInfoBox.image && (
            <Figure node={page.relatedSiteInfoBox.image} className='md:w-1/2 flex-shrink-0' aspectRatio={1} />
          )}

          <div className='p-5 pb-8 md:p-16'>
            <h2 className='font-sans-serif font-bold text-black mb-8'>{page.relatedSiteInfoBox.title}</h2>
            {page.relatedSiteInfoBox._rawText && <BlockContent className='mb-8' blocks={page.relatedSiteInfoBox._rawText} />}

            {page.relatedSiteInfoBox.linkText && (
              <a
                href="https://www.hanshelse.no/"
                className='flex items-center relative px-2 w-1/2 mb-4 lead'
              >
                <img src={ArrowRightIcon} alt='' className='w-4 mr-6' />
                {page.relatedSiteInfoBox.linkText}
              </a>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default IndexPage

export const query = graphql`
  {
    page: sanityFrontpage(_id: {regex: "/(drafts.|)frontpage/"}) {
      title
      _rawShortDescription(resolveReferences:{maxDepth:100})
      featuredServices {
        text
        url
        internalLink: _rawInternalLink(resolveReferences:{maxDepth:4})
        image {
          asset {
            id
            fluid(maxWidth: 480) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      serviceAreas {
          text
          url
      }
      image {
        asset {
          id
          fluid(maxWidth: 1280) {
            ...GatsbySanityImageFluid
          }
        }
        alt
      }
      _rawSlogan
      howItWorksText {
        title
        text: _rawText(resolveReferences:{maxDepth:100})
        image {
          asset {
            id
            fluid(maxWidth: 200) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      experts {
        id
        name
        image {
          alt
          asset {
            fluid(maxWidth: 452) {
              ...GatsbySanityImageFluid
            }
          }
        }
        description: _rawDescription
        position
      },
      _rawExpertsText(resolveReferences:{maxDepth:100})
      _rawOurServicesText(resolveReferences:{maxDepth:100})
      followUsText {
        title
        _rawText(resolveReferences:{maxDepth:100})
      }
      relatedSiteInfoBox {
        title
        image {
          asset {
            id
            fluid(maxWidth: 1280) {
              ...GatsbySanityImageFluid
            }
          }
        }
        _rawText(resolveReferences:{maxDepth:100})
        linkText
      }
      seo {
        title
        description
      }
    }

    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      pageDescriptionBlock {
        image {
          asset {
            id
            fluid(maxWidth: 1280) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }

    categories: allSanityCategory {
      edges {
        node {
          id
          title
          slug {
            current
          }
        }
      }
    }

    blog: allSanityBlog(sort: {
      fields: _createdAt,
      order: DESC
    }, limit: 2) {
      edges {
        node {
          ...BlogPostPreview
        }
      }
    }
  }
`
